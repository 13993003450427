import * as React from "react";

const LegalWarningTR = () => {
  return <div>
    <ul className={"ordered-text-items"}>
      <li>
        1. Piyasa verileri, Forinvest Yazılım ve Teknoloji Hizmetleri A.Ş. (Foreks), CoinMarketCap ve <a
          href={"https://polygon.io"}
                                                                                        target={"_blank"}>Polygon.io</a> Inc.
        tarafından sağlanmaktadır;
        gösterilen fiyatlar ‘son işlem’ fiyatlarıdır; BIST ve Eurobond verileri 15 (onbeş) dakika gecikmelidir.
      </li>
      <li>
        2. Finansal varlıkların gösterilen güncel değerleri (‘son işlem’ fiyatları üzerinden), değişimleri veya kar/zarar hesaplamaları yatırım danışmanlığı hizmeti sağlanması
        olarak anlaşılamaz veya yorumlanamaz; bu hizmet yalnızca müşteri ile kanunen yetkili kuruluşlar arasında imzalanacak yatırım danışmanlığı hizmet sözleşmesi çerçevesinde
        sağlanabilir.
      </li>
      <li>
        3. Finansal varlıkların gösterilen güncel değerleri, değişimleri veya kar/zarar hesaplamalarına dayanan yatırım kararları, beklenen sonuçları sağlamayabilir.
      </li>
      <li>
        4. Paramla Finansal Danışmanlık A.Ş., web sitesi veya mobil uygulamaları için kullanılan kaynaklarca sağlanan hatalı veya noksan veriler nedeniyle veya web sitesi veya
        mobil uygulamalarında sağlanan bilgilerin kullanılması sonucunda oluşabilecek doğrudan veya dolaylı zarar, kâr kaybı, manevi zarar veya üçüncü kişilerin uğrayabileceği
        herhangi bir zarardan hiçbir şekilde sorumlu tutulamaz.
      </li>
    </ul>
  </div>
}

export default LegalWarningTR;
