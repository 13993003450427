import * as React from "react";
import LayoutPlain from "../../components/LayoutPlain";
import LegalWarningTR from "content/LegalWarningTR";

const LegalWarningTRPlainPage = () => {
  return <LayoutPlain>
    <LegalWarningTR/>
  </LayoutPlain>
}

export default LegalWarningTRPlainPage;